import { useState, useEffect } from 'react';

export const useCategories = () => {
    const [isLoading, setIsLoading ] = useState(true)
    const [categories, setCategories] = useState([])

    const fetchCategories = async () => {
        const response = await fetch("http://localhost/clackapi/category.php?api_key=ewisoq")
        const CategorieJson = await response.json()
        const mapCategorieJson = CategorieJson.map(category => {
            return {
                CategoryChannel: category.CategoryChannel
            };
        })
        setCategories(mapCategorieJson)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    return { isLoading, categories}
}