import { useEffect, useState } from 'react';
import './App.css';
import HeaderGrilla from './components/HeaderGrilla';
import GrillaResult from './components/grillaResults';
import ScheludeProvider from './context/positionbar/Provider';
import ModalResults from './components/ModalResults';
import GrillaMovil from './components/GrillaMovil';

function App() {
  const [data, setData] = useState([]);
  const [currSec, setCurrSec] = useState(null);
  const [ categorie, setCategorie ] = useState('');
  const [ dateQuery, setDateQuery ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);
  const [ modal, setModal ] = useState(false);
  const [ searchResult, setSearchResult ] = useState([]);
  const [ isMobile, setIsMobile ] = useState(false);

  useEffect(() => {
    document.body.onresize = function() {
      if(document.body.offsetWidth < 1200){
        setIsMobile(true);
      }else{
        setIsMobile(false);
      }
    }
  })
  
  useEffect(() => {
    let d = new Date();
    let datestring = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getDate();
    setDateQuery(datestring);
  }, []);

  useEffect(() => {
    const getData = async () => {
      fetch(`http://localhost/clackapi/?api_key=ewisoq&q=${dateQuery}` ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
      .then(function(response){
        return response.json();
        
      })
      .then(function(myJson) {
        setData(myJson);
        setIsLoading(false);
      });
    };

    getData().catch(null);

    let date = new Date();
    let currHour = date.getHours() * 60;
    let currMinutes = date.getMinutes();
    let currSecInDy = ((currHour + currMinutes) * 60)/10;

    setCurrSec(currSecInDy);

  }, [dateQuery]);

  const containerStyle = {
    'width': '100%'
  }

  function _handleCategorie(categoria){
    setCategorie(categoria);
  }

  function _handleDay(day){
    setIsLoading(true);
    setDateQuery(day);
  }
  function _handleModal(){
    setModal(!modal);
  }
  function _handleResult(results){
    setModal(true);
    setSearchResult(results);
  }
  if(document.body.offsetWidth < 1200){
    return(
      <ScheludeProvider>
        <div className="guia-mobile grid" style={containerStyle}>
                <HeaderGrilla datosProgramas={data} currentSec={currSec} onChangeCat={_handleCategorie} onChangeDay={_handleDay} onSearchResult={_handleResult} esMovil={isMobile} />
                {isLoading ? <div className='loading-holder'><img src='./img/layout/loading.gif' alt='cargando...' /></div> : <GrillaMovil datosProgramas={data} categorie={categorie} currentData={dateQuery} />}
                {modal && <ModalResults onClickClose={_handleModal} results={searchResult} />}
        </div>
      </ScheludeProvider>
    )
  }
  return (
     <ScheludeProvider>
      <div className="guia-dektop grid" style={containerStyle}>
              <HeaderGrilla datosProgramas={data} currentSec={currSec} onChangeCat={_handleCategorie} onChangeDay={_handleDay} onSearchResult={_handleResult} esMovil={isMobile} />
              {isLoading ? <div className='loading-holder'><img src='./img/layout/loading.gif' alt='cargando...' /></div> : <GrillaResult datosProgramas={data} categorie={categorie} currentData={dateQuery} />}
              {modal && <ModalResults onClickClose={_handleModal} results={searchResult} />}
      </div>
    </ScheludeProvider>
  )
}

export default App;
