import { useState, useEffect } from "react";
export default function DaySelect({onChangeQueryDay}){
    const [ dias, setDias ] = useState([]);

    useEffect(() => {
        function getweek(curr) {
   
            if (curr instanceof Date){
              curr.setDate(curr.getDate()-1);
              let week=[];
              let dayRel = ["Hoy,", "Mañana,", "", "", "", "", ""]
              for(var i = 0; i<7; i++) {   
                week.push(getday(new Date( curr.setDate(curr.getDate() + 1)), dayRel[i]));            
              }
              return week;
            } else {
              return("Debe enviar una fecha valida")}
          }
          
          function getday(date, dayrelacional){
             
            let months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Juio", "Agosto","Septiembre","Octubre", "Noviembre", "Diciembre"];
            let days = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sábado"];
            
              let curr_date = ("0" + date.getDate()).slice(-2);
              let curr_month = date.getMonth();
              let curr_year = date.getFullYear();
              let day = days[date.getDay()];
          
              return ({dayname: day , daynumber:curr_date, monthname : months[curr_month], year: curr_year, monthNumber: curr_month, dayrel: dayrelacional, monthdef: ("0"+(curr_month+1)).slice(-2)});
          }
          
          setDias(getweek(new Date()));

    }, [])

    const _handleDay = (e) => {
        onChangeQueryDay(e.target.value)
    }


    return(
        <div className="select_clack-day">
            <select onChange={_handleDay}>
                {dias.map(day => {
                                return (

                                    <option 
                                        key={`${day.dayname}_${day.monthname}`}
                                        value={`${day.year}-${day.monthdef}-${day.daynumber}`}
                                    >
                                        {day.dayrel}  {day.dayname} {day.daynumber}
                                    </option>
                                )
                            })}
                            
            </select>
            <i></i>
        </div>
    )
}