import DaySelect from "./daySelect";
import CategoryFilters from "./categoryFilters";
import SearchBox from "./searchBox";

export default function FilterBar({scheludeData, onChangeCate, onChangeDayQuery, onSearchResult}){

    function _handleSearchClick(searchText) {
        const searchTextMinus = searchText.toLowerCase();
        const scheludeToArray = Object.entries(scheludeData);
        const scheludeMap = scheludeToArray.map((schelude)  => {
            return schelude[1]["schelude"];
        })
        const programMap = [];
        scheludeMap.forEach(program => {
            for(let a = 0; a < program.length; a++){
                let ProgramNameString = program[a]['ProgramName'];
               if(ProgramNameString?.toLowerCase().includes(searchTextMinus)){ 
                   programMap.push(program[a]);
               }
            }
        });
        onSearchResult(programMap);

    }

    function _handleCategoria(categoria){
        onChangeCate(categoria);
    }

    function _handleDay(day){
        onChangeDayQuery(day)
    }

    return (
        <div className="filters-clack">
            <DaySelect onChangeQueryDay={_handleDay} />
            <CategoryFilters onChangeCateg={_handleCategoria} />
            <SearchBox onSearch={_handleSearchClick} />
        </div>
    )
}