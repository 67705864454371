import HourBar from "./hourBar";
import FilterBar from "./filterBar";
import './style.css';

export default function HeaderGrilla({datosProgramas, currentSec, onChangeCat, onChangeDay, onSearchResult}){

    function _handleCategorie(categoria){
        //console.log("headergrilla", categoria)
        onChangeCat(categoria)
    }

    function _handleDay(day){
        //console.log("headergrilla", day)
        onChangeDay(day)
    }
    function _handleResult(results){
        onSearchResult(results)
    }
    return (
        <div className='headerGrilla'>
            <FilterBar scheludeData={datosProgramas} onChangeCate={_handleCategorie} onChangeDayQuery={_handleDay} onSearchResult={_handleResult} />
            {document.body.offsetWidth > 1200 && <HourBar currSec={currentSec} />}
        </div>
    )
}