import { useState, useEffect, useContext } from "react";
import ScheludeContext from "../../../context/positionbar";

export default function HourBar({currSec}){
    const [ horas, setHoras ] = useState([]);
    const [ widthContenedor, setWidthContenedor ] = useState();
    const { setPositionLeft } = useContext(ScheludeContext);

    useEffect(() => {
        const hoursOfADay=['00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30']


                    /*if(document.body.offsetWidth < 1200){
                        setHoras(hoursOfADayMobile);
                    }else{
                        setHoras(hoursOfADay);
                    }*/
                    setHoras(hoursOfADay);
        

              
        
     }, [/* */]);

     useEffect(() => {
        const navHour = document.getElementById("nav-hour");
        const widthNav = navHour.clientWidth / 2;
        setWidthContenedor(widthNav);
     }, [])

     
     let posBar = [];
     
     let positionCont = currSec > (widthContenedor / 2) ? (currSec - widthContenedor) * -1 : 0;
            posBar = {
                left: positionCont
            };
     useEffect(() => {
        if(widthContenedor){
            setPositionLeft(positionCont);
        }
     })

     
    

    function handlePrevHour () {  
        setWidthContenedor(widthContenedor + 180);
    }

    function handleNextHour () {  
        setWidthContenedor(widthContenedor - 180);
    }

    


    return (
        <div className="hourbar-container">
           {(currSec - widthContenedor) * -1 < 0 ?  <button 
                className="arrow-hour arrow-hour--prev"
                onClick={handlePrevHour}
                ><i></i></button> 
                :<button className="arrow-hour arrow-hour--prev disabled" disabled><i></i></button>
            }
            <div className="navigation-hour" id="nav-hour">
                <ul className="hour-ul" id="hour-ul" style={posBar}>
                    {horas.map(( hora, index) => {
                            return (
                                <li key={hora + index} className="slide-link slide-link-2"> {hora}</li>
                            )
                        })}
                </ul>
            </div>

            {(currSec - widthContenedor) * -1 > -8640 ?  <button 
                className="arrow-hour arrow-hour--next"
                onClick={handleNextHour}
                ><i></i></button> 
                :<button className="arrow-hour arrow-hour--next disabled" disabled><i></i></button>
            }
        </div>
    )
}