import { useEffect, useState } from "react";
import { useCategories  } from "../../../shared/hooks/getCategories";

export default function CategoryFilters({onChangeCateg}){
    const { categories, isLoading } = useCategories();
    const [ currentCategorie, setCurrentCategorie ] = useState('');

    useEffect(() => {
        setCurrentCategorie('');
    }, []);

    if(isLoading) return (<div>...Cargando</div>)

    const _handleCategories = (e) => {
        onChangeCateg(e.target.value);
        setCurrentCategorie(e.target.value)
    }
    //console.log(currentCategorie);
    if(document.body.offsetWidth < 1200){
        return (  
        <div className="select_clack-day" >
            <select onChange={_handleCategories} >
                <option>Todos</option>
                {categories?.map((item, index) => {
                    return (
                        <option
                            key={index}
                            value={item.CategoryChannel}
                        >
                            {item.CategoryChannel}
                        </option>
                    )
                })}
            </select>
            <i></i>
        </div>
        )
    }
    return (
        <ul className="categories-filter">
            <li 
                className="categories-filter--item"
            >
                <button 
                    value={""} 
                    onClick={_handleCategories}
                    className={currentCategorie === '' ? 'active' : ''}
                >Todos</button>
            </li>
            {categories?.map((item, index) => {
                return (
                    <li
                        key={index}
                        className="categories-filter--item"
                    >
                        <button 
                            onClick={_handleCategories}
                            value={item.CategoryChannel}
                            className={item.CategoryChannel === currentCategorie ? 'active' : ''}
                        >
                            {item.CategoryChannel}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}