import { useState } from "react"

export default function SearchBox({onSearch}){
    const [ isSearching, setIsSearching ] = useState(false);
    const [ inputSearch, setInputSearch ] = useState('');

    const _handleIsSearching = (e) => {
        setIsSearching(!isSearching)
    }
    const _handleChange = (e) => {
        setInputSearch(e.target.value)
    }
    const _handleSubmit = (e) => {
        e.preventDefault();
        onSearch(inputSearch)
    } 
    //console.log(isSearching)
    return (
        <form className={`searchbox-clack ${isSearching ? 'searching' : ''}`} onSubmit={_handleSubmit}>
            <button><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.0576 20.363C22.4406 19.772 21.8402 19.1641 21.2569 18.5399C20.7669 18.042 20.4718 17.6798 20.4718 17.6798L16.7836 15.9186C18.2602 14.244 19.0751 12.0881 19.0756 9.85541C19.0756 4.7722 14.9394 0.634766 9.85492 0.634766C4.77039 0.634766 0.634277 4.7722 0.634277 9.85541C0.634277 14.9386 4.77039 19.076 9.85492 19.076C12.1772 19.076 14.294 18.2067 15.9182 16.7854L17.6793 20.4736C17.6793 20.4736 18.0415 20.7687 18.5394 21.2587C19.0492 21.7369 19.7197 22.3836 20.3625 23.0594L22.1513 24.893L22.9469 25.7439L25.7408 22.95L24.8898 22.1544C24.3906 21.6644 23.7241 21.0137 23.0576 20.363ZM9.85492 16.4416C6.2233 16.4416 3.26875 13.487 3.26875 9.85541C3.26875 6.22379 6.2233 3.26923 9.85492 3.26923C13.4865 3.26923 16.4411 6.22379 16.4411 9.85541C16.4411 13.487 13.4865 16.4416 9.85492 16.4416Z" fill="white"/>
</svg></button>
            <input 
                type={'text'} 
                name="searchInput"
                onFocus={_handleIsSearching}
                onBlur={_handleIsSearching}
                onChange={_handleChange}
                value={inputSearch}
            ></input>
        </form>
    )
}