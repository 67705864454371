import { useEffect, useState} from "react";
import $ from 'jquery';

export default function ScheludeMovil({datosProgramas, categorie, currentData}){
    const [ results, setResults ] = useState([]);
    useEffect(() => {
        const scheludeToArray = Object.entries(datosProgramas);
        const scheludeMap = scheludeToArray.map((schelude)  => {
            return schelude[1];
        })
        const filteredData = scheludeMap.filter((schelude) => 
            schelude.canal.CategoryChannel.includes(categorie)
        )
        setResults(filteredData);
    }, [datosProgramas, categorie])
    

    useEffect(() => {
        $('.schelude-mobile-row').click(function (){
            $(this).toggleClass("active");
        })
    })

    
    return (
        <div className="programacion-canales--container">
        {results?.map(valor => {
            const RutaImage = "./img/" + valor.canal.IdChannel+ '.png';


            return(
                <div key={valor.canal.SignalChannel} className="schelude-mobile-row">
                    <div className="schelud-header">
                        <img
                        src={RutaImage}
                        alt={valor.canal.Channel}
                        height="72px"
                        />
                        <h3>Canal {valor.canal.SignalChannel}</h3>
                    </div>
                    <ul className="schelude-mobile-container-ul">
                        {valor.schelude.map(programa => {
                             return (
                                    <li key={programa.IdProgram+programa.SignalChannel}>
                                        <span className={`hora-programa`}>{programa.Hour.substring(0,5)}</span>
                                        <span>{programa.ProgramName}</span>
                                    </li>
                                );
                        })}
                        
                    </ul>
                    <div className="btn-holder">
                    <i></i>
                    </div>
                </div>
            )
        })}
        </div>
    );
}