import ScheludeContext from "./index";
import { useState } from "react";

export default function ScheludeProvider ({children}) {
    const [positionLeft, setPositionLeft] = useState();

    return (
        <ScheludeContext.Provider value={{ positionLeft, setPositionLeft}}>
            {children}
        </ScheludeContext.Provider>
    );
}

