import { useContext, memo } from 'react';
import ScheludeContext from '../../context/positionbar';

function time_format(d) {
    let hours = format_two_digits(d.getHours());
    let minutes = format_two_digits(d.getMinutes());
    return hours + ":" + minutes;
}

function format_two_digits(n) {
    return n < 10 ? '0' + n : n;
}

const Programa = memo(function Programa({ programa, currentData }){
    let currentHora = new Date();
    let currentHoraHour = currentHora.getHours() * 60;
    let currentHoraMinutes = currentHora.getMinutes();
    let currentHoraInSec = ((currentHoraHour + currentHoraMinutes) * 60)/10;


    let medidaLi
    if(programa.Duration < 100){
        medidaLi = (Number(programa.Duration) * 6);
    }else{
        let horas = '';
        let minutos = programa.Duration.substring(programa.Duration.length - 2);
        if(programa.Duration.length > 2){
            horas = Number(programa.Duration.substring(0, 1));
        }else{
            horas = 0;
        }
        var horasToSec = (Number(horas) * 60)*60;
        var minutostoSec = (Number(minutos) * 60);
        var segundosTotales = (Number(horasToSec) + Number(minutostoSec))/10;
        medidaLi = segundosTotales;
    }
    var d = new Date(programa.Day + ' ' +  programa.Hour);
    var formatted_time = time_format(d);
    let currHour = d.getHours() * 60;
    let currMinutes = d.getMinutes();
    let currSecInDy = ((currHour + currMinutes) * 60)/10;

    let diafin = new Date(programa.Day + ' ' +  programa.Hour);
    diafin.setMinutes(diafin.getMinutes() + ((Number(medidaLi) * 10)/60));
    let finHour = diafin.getHours();
    let finMinutes = diafin.getMinutes();
    let horafin = finHour + ':' + (finMinutes<10?'0':'') + finMinutes;
    let finHourInSec = (((finHour * 60) *60) + (finMinutes *60)) / 10;

    let porcentajeProgreso = currentHoraInSec - currSecInDy;
    porcentajeProgreso = (porcentajeProgreso * 100)/medidaLi;

    let datestring = currentHora.getFullYear() + "-" + ("0"+(currentHora.getMonth()+1)).slice(-2) + "-" + currentHora.getDate();
    let isToday = currentData === datestring;

    /*if(document.body.offsetWidth < 1200){
        medidaLi = medidaLi/2;
        currSecInDy = currSecInDy/2;
    }*/

    const StyleLi = {
        'width': (medidaLi - 4),
        position: 'absolute',
        top:0,
        left: currSecInDy,
        boxSizing: 'border-box',
        
        backgroundColor: ((currentHoraInSec > currSecInDy) && (currentHoraInSec > finHourInSec) && ((medidaLi + currSecInDy)  < 8640) && isToday) ? 'rgba(255, 44, 131, 0.3)' : 'rgba(255, 255, 255, .2)'
    };
    /*display: (medidaLi < 30) ? 'none' : 'block', */
    const StyleProgress = {
        width: isToday && (porcentajeProgreso > 0 && porcentajeProgreso < 101) && porcentajeProgreso + '%'
    }
    let StyleParrafo = {
        width: (medidaLi < 360) ? (document.body.offsetWidth > 1200 ? 180 : 50) : '100%'
    }

    //console.log(porcentajeProgreso);
    if(programa){
        return (
            <li key={programa.IdProgram + programa.Hour} style={{...StyleLi, ...{'left': currSecInDy}}}>
                <div title={programa.ProgramName + ' - ' + formatted_time}>
                    <div className="caption-schelude">
                        <p style={{...StyleParrafo}} className="title-program" >{programa.ProgramName}</p>
                        <p>{formatted_time} <span className='hour-end'> - {horafin}</span></p>
                    </div>
                    <span className='progress-bar' style={{...StyleProgress}}></span>
                </div>
            </li>
        )
    }
});

export default function ScheludeResultChannel({scheludeData, currentData}){
    let { positionLeft } = useContext(ScheludeContext);

    

    let currentHora = new Date();
    let currentHoraHour = currentHora.getHours() * 60;
    let currentHoraMinutes = currentHora.getMinutes();
    let currentHoraInSec = ((currentHoraHour + currentHoraMinutes) * 60)/10;
    let datestring = currentHora.getFullYear() + "-" + ("0"+(currentHora.getMonth()+1)).slice(-2) + "-" + currentHora.getDate();
    let isToday = (currentData === datestring);


   /* if(document.body.offsetWidth < 1200){
        currentHoraInSec = currentHoraInSec/2;
        positionLeft = positionLeft/2;
    }*/

    let stylePositionClavija = {
        left: isToday && currentHoraInSec
    }

    let StylePosition= {
        left: positionLeft,
    };

    return (
        <ul className="schelude-result" style={StylePosition}>
            {scheludeData.map((programa) => {
                return <Programa key={programa.IdSchelude} programa={programa} currentData={currentData} />
            })}
            <span className='clavija-tiempo' style={stylePositionClavija}></span>
        </ul>
    )
}