export default function ChannelData({scheludeData}){

   // console.log("canal", scheludeData);
  const RutaImage = "./img/" + scheludeData.IdChannel + '.png';
    return (
        <div key={scheludeData.IdChannel} className="canal-card">
            <span>{scheludeData.SignalChannel}</span>
            <span className="canal-logo">
                <img 
                    src={RutaImage }
                    alt={scheludeData.Channel}
                    title={scheludeData.Channel}
                />
            </span>
        </div>
        
    );
}