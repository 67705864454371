import './style.css';

export default function ModalResults({onClickClose, results}){

    //console.log("modal", results);
    const BgStyle = {
        backgroundImage: "url('./img/layout/bg.png')"
    }
    const _handleClose = (e) => {
        onClickClose();
    }
    return (
        <div className='modal-search-results'>
            <div className='modal-search-result-header' style={BgStyle}>
                <div className='cta-holder'>
                    <button onClick={_handleClose}><img src='./img/layout/close.svg' alt='cerrar' /></button>
                </div>
                <div className='title-holder'>
                    <h2>Resultados de busqueda</h2>
                </div>
            </div>
            <div className='modal-search-result-body'>
                <div className='results-modal-holder'>

                    <table className='search-results'>
                        <thead>
                            <tr>
                                <td>Titulo</td>
                                <td>Horario</td>
                                <td>Canal</td>
                                <td>Número</td>
                            </tr>
                        </thead>
                        <tbody>
                            {results?.map((programa) => {
                                return(<tr key={programa.IdProgram}>
                                    <td>{programa.ProgramName}</td>
                                    <td>{programa.Hour}</td>
                                    <td>{programa.Channel}</td>
                                    <td>{programa.SignalChannel}</td>
                                </tr>)
                            })}
                            {results.length < 1 && <p className='no-result'>No hay resultados</p>}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    )
}