import { useEffect, useState} from "react";
import ChannelData from "./channelData";
import ScheludeResultChannel from "./scheludeResultChannel";

export default function Schelude({datosProgramas, categorie, currentData}){
    const [ results, setResults ] = useState([]);
    //console.log("categoria", categorie)

    useEffect(() => {
        const scheludeToArray = Object.entries(datosProgramas);
        const scheludeMap = scheludeToArray.map((schelude)  => {
            return schelude[1];
        })
        const filteredData = scheludeMap.filter((schelude) => 
            schelude.canal.CategoryChannel.includes(categorie)
        )
        setResults(filteredData);
    }, [datosProgramas, categorie])

    //console.log('Aqui wn', results);
    
    return (
        <div className="programacion-canales--container">
        {results?.map(valor => {
            return(
                <div key={valor.canal.SignalChannel} className="schelude-row">
                    <ChannelData scheludeData={valor.canal} />
                    <div className="schelude-container-ul">
                        <ScheludeResultChannel  scheludeData={valor.schelude} currentData={currentData}/>
                    </div>
                </div>
            )
        })}
        </div>
    );
}